var Masonry = require('masonry-layout'),
	$ = require('jquery');

var Grid = {
    init: function() {
    	var grid = document.querySelector('.grid');
		$(window).load(function() {
			var msnry = new Masonry( grid, {
				// options
				itemSelector: '.grid-item',
				columnWidth: '.grid-sizer',
				percentPosition: false
			});
		});
    }
};

module.exports = Grid;
