var $ = require('jquery'),
	Helpers = require('./helpers');

var SocialHelper = {

	isMobile: window.innerWidth < 769 || document.documentElement.clientWidth < 769 || document.body.clientWidth < 769,

	init: function() {
		SocialHelper.initFB();
		SocialHelper.initTwitter();
	},

	initFB: function() {

		function postToFeed() {
			var title = $('meta[property="og:title"]').attr('content'),
				img = $('meta[property="og:image"]').attr('content'),
				url = $('meta[property="og:url"]').attr('content'),
				desc = $('meta[property="og:description"]').attr('content'),
				redirect = 'http://antibananagymbag.com',
				applicationId = '1638200363114627';

			var obj = {
				method: 'feed', 
				link: url, 
				name: title, 
				description: desc, 
				picture: img,
				redirect_uri: redirect,
				app_id: applicationId				
			};

			var string = 'https://www.facebook.com/dialog/feed?app_id=' + applicationId + '&display=popup&link=' + url + '&redirect_uri=' + redirect + '&name=' + title + '&description=' + desc + '&picture=' + img;		

			function callback(response) {}

			if (SocialHelper.isMobile) {
				window.location.href = string;
			} else {
				FB.ui(obj, callback);
			}
		}

		// Add click event to facebook sharing in header
		$('.fb_share').on('click', function(e) {
			e.preventDefault;
			postToFeed();
			return false;
		});

	},

	initTwitter: function() {

		// sharing for twitter in header
		$('.twitter-share-button').click(function(event) {
			var width  = 575,
			    height = 400,
			    left   = ($(window).width()  - width)  / 2,
			    top    = ($(window).height() - height) / 2,
			    url    = this.href,
			    opts   = 'status=1' +
			             ',width='  + width  +
			             ',height=' + height +
			             ',top='    + top    +
			             ',left='   + left;

			window.open(url, 'twitter', opts);

			return false;
		});

	}

};

module.exports = SocialHelper;