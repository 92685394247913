'use strict';

var $ = require('jquery'),
	Carousel = require('./modules/carousel'),
	Grid = require('./modules/grid'),
	Modals = require('./modules/modals'),
	SocialHelper = require('./modules/socialhelper'),
	AttachFastClick = require('fastclick');

var app = {
    init: function() {
    	// Fastclick event
    	AttachFastClick(document.body);

        // Initialize carousel
		Carousel.init();

		/* video settings */
    	$('.venobox').venobox(); 

		/* iframe settings */
    	$('.venobox_custom').venobox({
	        framewidth: '',
	        frameheight: '',
	        border: '0',
	        bgcolor: 'transparent',
	        titleattr: 'data-title',
	        numeratio: false,
	        infinigall: false
	    });
    	// Initialize grid
	    Grid.init();
	    // Initialize Social Helper
	    SocialHelper.init();
    }
};

app.init();
